<i18n>
	{
		"en": {
			"transferTitle": "Transfer Fund",
			"phoneNumber": "Phone Number of Recipient *",
			"amount": "Amount to transfer (Rs.) *",
			"paymentDetails": "Add Payment Details *",
			"exampleDetails": "Eg. Rent Money, Lunch Money",
			"payNow": "Pay Now"
		},
		"np": {
			"transferTitle": "स्थानान्तरण कोष",
			"phoneNumber": "प्रापकको फोन नम्बर*",
			"amount": "स्थानान्तरण गर्ने रकम (रु.) *",
			"paymentDetails": "भुक्तानी विवरणहरू थप्नुहोस् *",
			"exampleDetails": "जस्तै भाडाको पैसा, खाजाको पैसा",
			"payNow": "तिर्नुहोस्"
		}
	}
</i18n>
<template lang="pug">
	.container
		.wallet-form-box.card
			.card-body
				.row
					.col-sm-10.col-lg-6
						h4 {{ $t("transferTitle") }}
						.show-message(v-if='message') {{message}}
						transition(name='fade' mode='out-in')
							form(ref='formRef' @submit.prevent='makePayment')
								label {{ $t("phoneNumber") }}
								.form-group.input-group
									.input-group-prepend
										.input-group-text +977
									input.form-control(name='recipent' type='tel' v-model='transfer.phoneNumber' maxlength='10' required)
								.form-group
									label {{ $t("amount") }}
									input.form-control(type='number' min='100' max='100000' v-model='transfer.amount' required)
								.form-group
									label {{ $t("paymentDetails") }}
										label.small ({{ $t("exampleDetails") }})
									input.form-control(type='text' v-model="transfer.purpose" required)
								button.btn.btn-primary(:disabled="loading") {{ $t("payNow") }}
								div(v-if='paymentModal')
									pin-confirm(:previewDetails='previewDetails' :pinVerification='verifyPin')
		loading-spinner( :loading="loading")
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import PinConfirm from "@/components/PinConfirm";
import payment from "@/mixins/payment";
import axios from "@/axios";
export default {
	mixins: [payment],
	name: "load-fund",
	components: { LoadingSpinner, PinConfirm },
	data() {
		return {
			transfer: {
				receiverId: "",
				source: "Wallet",
			},
			message: "",
			otpsent: false,
			loading: false,
			otp: "",
		};
	},
	computed: {
		previewDetails() {
			return {
				"Recipent Number": this.transfer.phoneNumber,
				"Total Amount to Pay": this.transfer.amount,
			};
		},
	},
	methods: {
		resetInfo() {
			this.otpsent = false;
			this.transfer.phoneNumber = "";
			this.otp = "";
			this.transfer.receiverId = "";
			this.transfer.paymentDetails = "";
			this.transfer.amount = "";
		},
		async submitOTP() {
			this.loading = true;
			this.message = "";
			const r = await axios.post("walletTransfer/verify", {
				authorizedCode: this.otp,
			});

			this.loading = false;
		},
		verifyPin(valid) {
			this.transfer.pin = valid;
			this.pinVerification(this.makePayment, valid);
		},
		async makePayment() {
			if (!this.pinVerified) {
				this.paymentModal = true;
				return;
			}
			this.loading = true;
			const validUserResponse = await axios.get("api/v1/walletTransfer/receiverDetails", {
				params: {
					phoneNumber: `+977${this.transfer.phoneNumber}`,
				},
			});
			if (validUserResponse.data && validUserResponse.data.data.userDetails) {
				this.transfer.to = validUserResponse.data.data.userDetails._id;
			} else {
				this.message = "Phone number not registered as Nimbuzz user.";
				this.loading = false;
				return;
			}
			const transferResponse = await axios.post(
				"api/v4/fund_transfer/payment",
				this.transfer
			);
			if (transferResponse.data.status === 1) {
				this.resetInfo();
				this.$store.dispatch("UPDATE_BALANCE");
				this.message = "Successfully, transfered.";
			} else {
				this.message = transferResponse.data.message;
			}
			this.loading = false;
		},
	},
};
</script>
